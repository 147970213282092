<template>
	<div id="body">
		<div id="sel">
			<el-input
			    placeholder="请输入内容"
			    prefix-icon="el-icon-search"
					size='small'
			    v-model="seltxt">
			  </el-input>
				<button type="button">搜索</button>
		</div>
		<div id="storebox">
			<div id="storebox_flex">
				<img src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/hangyeruanjian.png" >
				<p>行业软件</p>
			</div>
			<div id="storebox_flex">
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/ZZfu.png" >
				<p>增值服务</p>
			</div>
			<div id="storebox_flex">
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/shouyin.png" >
				<p>收银设备</p>
			</div>
			<div id="storebox_flex">
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/huiyuan.png" >
				<p>电子会员</p>
			</div>
			<div id="storebox_flex">
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/baoxiu1.png" >
				<p>报修小程序</p>
			</div>
			<div id="storebox_flex">
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/gongchenghuitu.png" >
				<p>工程ERP</p>
			</div>
			<div id="storebox_flex">
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/PhoneApp.png" >
				<p>手机APP</p>
			</div>
			<div id="storebox_flex">
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/gongchengguanli.png" >
				<p>工程设备</p>
			</div>
		</div>
		<div id="shopdeta" v-for="(item,i) in Topone" :key='i' @click="selfun(item)">
			<img :src="item.Url" >
			<div id="rights">
				<p id="tit">{{item.Name}}</p>
				<p id="Pic">￥{{item.Price}}<span
									style="font-size: 12px; color: #888888;font-weight: normal;"> 起</span></p>
				<p id="Name">融恒线商城</p>
			</div>
		</div>
		
	</div>
</template>

<script>
	const axi =require('../../assets/axi.js')
	export default{
		data(){
			return{
				seltxt:"",
				Topone: [],
			}
		},
		mounted() {
			console.log(this.$route.query.code)
			if(this.$route.query.code){
				this.$store.commit('Invitationcodefun',this.$route.query.code)
			}
			axi.post('Commodity/Goods',{}).then((res)=>{
				console.log(res)
				if(res.data.Code==1000){
					let ObjArr=[]
					res.data.Dto.hotshop.map(item=>{
						ObjArr.push({...item})
					})
					res.data.Dto.shoplist.map(item=>{
						ObjArr.push({...item})
					})
					res.data.Dto.valueaddedservices.map(item=>{
						ObjArr.push({...item})
					})
					console.log(ObjArr)
					this.Topone=ObjArr
				}else{
					this.$message.error(res.data.Msg)
				}
				})
		},
		methods:{
			selfun(val){
				console.log(val)
				this.$store.commit('shopidfun',val)
				this.$router.push('/phonedatail')
			}
		}
	}
</script>

<style scoped lang="less">
	#sel{
		width: 90%;
		margin-top: 2%;
		margin-left: 5%;
		margin-bottom: 2%;
		.el-input{
			width: 80%;
			/deep/.el-input__inner{
				border-radius: 25px;
			}
		}
		button{
			width:50px;
			height: 30px;
			margin-left: 5px;
			border-radius: 25px;
			background-color: #fd3f31;
			color: #FFFFFF;
		}
	}
	#storebox{
		width: 100%;
		margin-top: 5%;
		margin-bottom: 5%;
		height: 140px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		box-shadow: 4px 7px 10px #f1ecec;
		// border-top: 6px solid #dcdcdc;
		// border-bottom: 6px solid #dcdcdc;
		#storebox_flex{
			width: 23%;
			height: 48%;
			text-align: center;
			img{
				max-width: 100%;
				height: 70%;
			}
			p{
				margin: 0;
				font-size: 12px;
			}
		}
	}
	#shopdeta{
		width: 90%;
		height: 120px;
		margin-left: 5%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #e6e6e6;
		img{
			display: block;
			width: 30%;
			max-height: 100%;
		}
		#rights{
			width: 65%;
			p{
				margin: 0;
				width: 70%;
			}
			#tit{
				font-size: 18px;
				font-weight: bold;
			}
			#Pic{
				color: #fd3f31;
				font-weight: bold;
				margin-top: 2%;
			}
			#Name{
				font-size: 12px;
				margin-top: 5%;
				color: #8a8a8a;
			}
		}
		
	}
</style>
